import React, { useState, useRef, useEffect } from 'react';
import { FaCalendar, FaCaretLeft, FaCaretRight } from "react-icons/fa";

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  className?: string;
  onKeyDown:(e: React.KeyboardEvent<HTMLInputElement>)=>void;
}

export function DateRangePicker({ startDate, endDate, onChange, className = '' }: DateRangePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(startDate ? startDate.getMonth() : new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(startDate ? startDate.getFullYear() : new Date().getFullYear());
  const [selectingEndDate, setSelectingEndDate] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const navigateMonth = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1);
      setCurrentYear(currentMonth === 0 ? currentYear - 1 : currentYear);
    } else {
      setCurrentMonth(currentMonth === 11 ? 0 : currentMonth + 1);
      setCurrentYear(currentMonth === 11 ? currentYear + 1 : currentYear);
    }
  };

  const handleDateSelect = (day: number) => {
    const selectedDate = new Date(currentYear, currentMonth, day);

    if (!selectingEndDate) {
      onChange(selectedDate, null);  // Set start date and reset end date
      setSelectingEndDate(true);
    } else {
      if (startDate && selectedDate < startDate) {
        onChange(selectedDate, startDate); // Swap if end date is before start date
      } else {
        onChange(startDate, selectedDate);
      }
      setSelectingEndDate(false);
      setIsOpen(false);
    }
  };

  const isInRange = (day: number) => {
    if (!startDate || !endDate) return false;
    const currentDate = new Date(currentYear, currentMonth, day);
    return currentDate > startDate && currentDate < endDate;
  };

  const isSelected = (day: number, date: Date | null) =>
    date &&
    day === date.getDate() &&
    currentMonth === date.getMonth() &&
    currentYear === date.getFullYear();

  const generateCalendarDays = () => {
    const days = [];
    const paddingDays = Array(firstDayOfMonth).fill(null);
    const monthDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    
    return [...paddingDays, ...monthDays].map((day, index) => {
      if (day === null) {
        return <div key={`empty-${index}`} className="h-8 w-8" />;
      }

      const selectedStart = isSelected(day, startDate);
      const selectedEnd = isSelected(day, endDate);
      const isToday =
        day === new Date().getDate() &&
        currentMonth === new Date().getMonth() &&
        currentYear === new Date().getFullYear();

      return (
        <button
          key={`day-${day}`}
          onClick={() => handleDateSelect(day)}
          className={`
            h-8 w-8 rounded-full flex items-center justify-center text-sm
            ${selectedStart || selectedEnd ? 'bg-indigo-600 text-white' : 'hover:bg-gray-100'}
            ${isToday && !selectedStart && !selectedEnd ? 'border border-indigo-600' : ''}
            ${isInRange(day) ? 'bg-indigo-100' : ''}
          `}
        >
          {day}
        </button>
      );
    });
  };

  const clearSelection = () => {
    onChange(null, null);
    setSelectingEndDate(false);
  };

  return (
    <div ref={wrapperRef} className={`relative ${className}`}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between bg-white px-4 py-2 shadow-tremor-input border-2 rounded-lg cursor-pointer hover:border-gray-500"
      >
        <span className={startDate ? "text-sm text-gray-700" :"text-sm text-gray-500"}>
          {startDate ? startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Start Date'} - {endDate ? endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'End Date'}
        </span>
        <FaCalendar className="w-3 h-3 text-gray-500" />
      </div>

      {isOpen && (
        <div className="absolute top-full mt-2 bg-white rounded-lg shadow-lg border p-4 w-[280px] z-50">
          <div className="flex items-center justify-between mb-4">
            <button
              onClick={() => navigateMonth('prev')}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <FaCaretLeft className="w-5 h-5" />
            </button>
            <div className="font-medium">
              {months[currentMonth]} {currentYear}
            </div>
            <button
              onClick={() => navigateMonth('next')}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <FaCaretRight className="w-5 h-5" />
            </button>
          </div>

          <div className="grid grid-cols-7 gap-1 mb-2">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className="h-8 w-8 flex items-center justify-center text-xs font-medium text-gray-500">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1">
            {generateCalendarDays()}
          </div>

          <button onClick={clearSelection} className="mt-3 w-full text-sm text-red-500">Clear Selection</button>
        </div>
      )}
    </div>
  );
}
