import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useRouter } from 'next/router';
import { baseUrl } from '../../app/shared/baseUrl';
import axios from 'axios';
import { Button, Title } from '@tremor/react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function Redirect_Upstrox() {
    const auth = useAppSelector((state)=>state.auth)
    const navigate = useNavigate();
    const location = useLocation();
    const [code, setCode] = useState<string | null>(null);
    const [status, setStatus]=useState(false);

    const authSet = (broker:string) => toast.success(`${broker} Broker Authenticated!`);

    useEffect( () => {
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(location.search);
            const codea = params.get('code');
            setCode(codea);
        }
    },[])

    const handleAction = async () => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        navigate('/broker');
    };

    useEffect(()=>{
        if(code){
            const sendData = async ()=>{
                try{
                    const response = await axios.post(`${baseUrl}/api/v2/brokers/upstox/get-access-token`, {code:code},
                    {
                        headers:{
                            'Authorization':`Bearer ${auth.authCode}`,
                            'Content-Type':'application/json'
                            }
                    });
                    if(response.status===200){
                        authSet('Upstox');
                        setStatus(true);
                        handleAction();
                    }
                }catch(e){
                    console.log(e);
                }
            }
            sendData();
        }
        },[code])
        return (
            <div style={{ height:'100vh', width:'100vw'}}>
                <Toaster position='top-right'/>
                {
                    status ?
                    <div>
                        <Title>Success</Title>
                    </div>
                    :
                    <Title>Loading...</Title>
                }
            </div>
        );
}

export default Redirect_Upstrox