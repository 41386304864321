// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__GuJBs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.App_app__GuJBs .App_terms__byWC5 {
  padding: 0;
  width: 45rem;
  margin-left: -25rem;
  margin-right: -8rem;
  border-radius: 1rem 1rem 0 0;
}
.App_app__GuJBs .App_terms__byWC5 .App_head__O\\+m7u {
  background-color: #E5E7EB;
  border-radius: 1rem 1rem 0 0;
  font-size: 2rem;
}
.App_app__GuJBs .App_terms__byWC5 .App_head__O\\+m7u .App_title__wJw7v {
  font-size: 1.5rem;
  font-weight: 600;
}
@media screen and (max-width: 600px) {
  .App_app__GuJBs .App_terms__byWC5 {
    width: 90%;
    margin-left: 0rem;
    margin-right: 0;
  }
  .App_app__GuJBs .App_terms__byWC5 .App_head__O\\+m7u .App_title__wJw7v {
    font-size: 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAAI;EACI,UAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,4BAAA;AAER;AADQ;EACI,yBAAA;EACA,4BAAA;EACA,eAAA;AAGZ;AAFY;EACI,iBAAA;EACA,gBAAA;AAIhB;AAGI;EACI;IACI,UAAA;IACA,iBAAA;IACA,eAAA;EADV;EAGc;IACI,iBAAA;EADlB;AACF","sourcesContent":[".app{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    .terms{\n        padding: 0;\n        width:45rem;\n        margin-left: -25rem;\n        margin-right: -8rem;\n        border-radius: 1rem 1rem 0 0;\n        .head{\n            background-color: #E5E7EB;\n            border-radius: 1rem 1rem 0 0;\n            font-size: 2rem;\n            .title{\n                font-size: 1.5rem;\n                font-weight: 600;\n            }\n        }\n        .details{\n\n        }\n    }\n    @media screen and (max-width:600px) {\n        .terms{\n            width: 90%;\n            margin-left: 0rem;\n            margin-right: 0;\n            .head{\n                .title{\n                    font-size: 1.5rem;\n                }\n            }\n        }\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__GuJBs`,
	"terms": `App_terms__byWC5`,
	"head": `App_head__O+m7u`,
	"title": `App_title__wJw7v`
};
export default ___CSS_LOADER_EXPORT___;
