import React, { useEffect, useState } from 'react'
import styles from './transaction.module.scss';
import { Badge, BadgeDelta, Button, Card, Switch, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput, Title } from '@tremor/react';
import { SortBy, type SortDirection } from './component/Short';
import { MdOutlineSearch } from "react-icons/md";
import { DateRangePicker } from './component/DatePicker';
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { GoPencil } from 'react-icons/go';
import botData from './component/transaction.json';
import axios from 'axios';
import { Currency } from '../../global/Componnets/Utils/Currency';
import { baseUrl } from '../../app/shared/baseUrl';
import { useAppSelector } from '../../app/hooks';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from '@mantine/core';

interface ResponseData{
    id: string
    transaction_numeric_id: string
    tradesignal_numeric_id: string
    signal_id: string
    customer_id: string
    admin_id: string
    start_action: string
    start_action_price: number
    start_action_datetime: string
    end_action: string
    profit: number
    capital_used: number
    trade_name: string
    quantity: number
    product: string
    validity: string
    price: number
    tag: string
    instrument_token: string
    order_type: string
    transaction_type: string
    disclosed_quantity: number
    trigger_price: number
    is_amo: boolean
    is_successful: boolean
    status: boolean
    order_placed_price: number
    trade_status: string
    user_broker: string
    square_off_type: string
    initial_order_trade: string
    stop_loss_order_trade: string
    profit_booking_order_trade: any
    initial_order_failure_reason: string
    square_off_time: string
    square_off_price: number
    created_at: string
    updated_at: string
    bot_name:string
    transaction_status:string
}

interface Details{
    last_seven_days_profit: number,
    overall_profit: number
}


interface DateRange {
    startDate: string
    endDate: string
}


interface DateRange1 {
    startDates: Date | null;
    endDates: Date | null;
}

function Transaction() {
    const [details, setDetails]=useState<Details>()
    const [transactionData, setTransactionData]=useState<ResponseData[]>([]);
    const handleSortChange = (direction: SortDirection) => {
        console.log('Sort direction changed:', direction);
        };
        const auth =useAppSelector((state) => state.auth)
        const [currentPage, setCurrentPage] = useState(1);
        const [fromDate, setFromDate]=useState<string>();
        const [toDate, setToDate]=useState<string>();
        const [search, setSearch]=useState<string>();
        const Errors = () =>toast.error(`No Transaction Found`);
        const [totalPage, setTotalPage]=useState<number>();
        const [selectedDate, setSelectedDate] = useState<DateRange>({
            startDate:'',
            endDate:''
        });
        const [selectedDates, setSelectedDates] = useState<DateRange1>(
            { startDates:null, endDates:null }
        );


        async function getData() {
            try {
                const response = await axios.get(`${baseUrl}/api/customers/profit-data`,{
                    headers: {
                    "Authorization": `Bearer ${auth.authCode}`,
                    "Content-type": "application/json",
                    }
                })
                if(response.status===200 || response.status===201){
                    setDetails(response.data)
                }
            } catch (error) {
                Errors();
            }
        }


        async function callForData(){
            console.log(selectedDate);
            console.log(search)
            try{
                const response= await axios.post(`${baseUrl}/api/customers/search-in-transactions`,
                {
                    size:15,
                    page:currentPage,
                    start_date:selectedDate?.startDate ? selectedDate?.startDate : '',
                    end_date:selectedDate?.endDate ? selectedDate?.endDate : "",
                    search_item:search
                },{
                    headers: {
                    "Authorization": `Bearer ${auth.authCode}`,
                    "Content-type": "application/json",
                    }
                })
                if(response.status===200){
                    setTransactionData(response.data.data);
                    setCurrentPage(response.data.current_page);
                    setTotalPage(response.data.total_pages)
                }
                else{
                    alert('Please Reload the Page');
                    setTransactionData([]);
                }
            }catch(error:any){
                if(currentPage!==1){
                    setCurrentPage(prevPage => prevPage - 1);
                }
                if (error.response.status == 401) {
                }
                if(error.response.status==502){
                    console.log(error)
                }
                setTransactionData([]);
                Errors();
                }
            }

        const handleNextPage = () => {
            if(currentPage == totalPage){
                return;
            }
            setCurrentPage(prevPage => prevPage + 1);
        };
        const handlePrevPage = () => {
            if(currentPage == 1){
                return;
            }
            setCurrentPage(prevPage => prevPage - 1);
        };
        useEffect(()=>{
            callForData();
            getData();
        },[currentPage]);


          // Define handleDateChange with specific parameter types
        const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
            setSelectedDates({startDates:startDate, endDates:endDate});
            const date = new Date(startDate as Date);
            const date1 = new Date(endDate as Date);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            const year1 = date1.getFullYear();
            const month1 = String(date1.getMonth() + 1).padStart(2, '0');
            const day1 = String(date1.getDate()).padStart(2, '0');


            const formattedDate = `${year}-${month}-${day}`;
            const formattedDate1 = `${year1}-${month1}-${day1}`;
            console.log(formattedDate);
            console.log(formattedDate1);
            setSelectedDate({
                startDate:formattedDate,
                endDate:formattedDate1
            });
        };
    return (
        <div className={styles.transaction}>
            <Toaster position='top-right' />
            <div className={styles.transactionPc}>
                <div className={styles.title}><Title className={styles.text}>Transaction</Title></div>
                <div className={styles.control}>
                    <div className={styles.input}>
                        <TextInput  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                        callForData();
                    }
                }} onValueChange={(value:string)=>setSearch(value)} icon={MdOutlineSearch} className={styles.inputField} placeholder='Search Bot, Instrument' />
                        <DateRangePicker
                            startDate={selectedDates?.startDates}
                            endDate={selectedDates?.endDates}
                            onChange={(startDate,endDate)=>handleDateChange(startDate,endDate)}
                            className="w-full"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' ) {
                                    callForData();
                                }
                            }}
                        />
                    </div>
                    <SortBy onSortChange={handleSortChange}  />
                </div>
                <div className={styles.details}>
                    <div className={styles.right}>
                        <Title className={styles.key}>In View &nbsp;<span style={{color:"green"}}> ₹{0}</span></Title>
                        <Title className={styles.key}>
                            Last 7 Days &nbsp;
                            <span style={{color: details?.overall_profit !== undefined && details.last_seven_days_profit < 0 ? "red" : "green"}}>
                                <Currency amount={details?.last_seven_days_profit || 0 } />
                            </span>
                        </Title>
                        <Title className={styles.key}>Overall &nbsp;<span style={{
                            color: details?.overall_profit !== undefined && details.overall_profit < 0 ? "red" : "green"
                            }}
                        >  
                        <Currency amount={details?.overall_profit || 0 } />
                        </span></Title>
                    </div>
                    <div className={styles.left}>
                        <Title className={styles.text}>
                            Showing {currentPage} page of {totalPage} pages 
                            <span style={{ cursor: currentPage == 1 ? 'default' : 'pointer' }}><FaAngleLeft  onClick={()=> currentPage !== 1 && handlePrevPage()} className={styles.custom_icon}  /></span>
                            <span style={{ cursor: currentPage ==  totalPage ? 'not-allowed' : 'pointer' }}><FaAngleRight onClick={()=>handleNextPage()}  className={styles.custom_icon}  /></span>
                        </Title>
                    </div>
                </div>
                <Table className={styles.table}>
                    <TableHead className={styles.tableHead}>
                        <TableRow className={styles.tableRow}>
                            <TableHeaderCell className={styles.tableHeadCell}>Bot</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Instrument</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Units</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Start/End Price</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Overall P/L</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Status</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Date</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.tableBody}>
                            {
                                transactionData?.map((row:ResponseData , index:number)=>{
                                    console.log()
                                    const date = new Date(row.start_action_datetime);
                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const hours = String(date.getHours()).padStart(2, '0');
                                    const minutes = String(date.getMinutes()).padStart(2, '0');
                                    const seconds = String(date.getSeconds()).padStart(2, '0');
                                    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                                    return(
                                    <TableRow className={styles.tableRow}>
                                        <TableCell className={styles.tableCell}>{row.bot_name}</TableCell>
                                        <TableCell className={styles.tableCell}>{row.trade_name}</TableCell>
                                        <TableCell /* style={{textAlign:'start'}}  */className={styles.tableCell}>{row.quantity}</TableCell>
                                        <TableCell className={styles.tableCell}>{row.order_placed_price===null ? 0 : <span style={row.order_placed_price<0 ? {color:"red"}:{color:"green"}}><Currency amount={row.order_placed_price || 0 } /></span> } / <span style={row.order_placed_price<0 ? {color:"red"}:{color:"green"}}>{row.square_off_price===null ? 0 : row.square_off_price}</span></TableCell>
                                        <TableCell className={styles.tableCell}>{row.profit==null ? 0 : <span style={row.profit<0 ? {color:"red"}:{color:"green"}}><Currency amount={row.profit || 0 } /></span>} / <span style={row.profit<0 ? {color:"red"}:{color:"green"}}>{Number.isNaN((row.profit/row.order_placed_price)*100) ? 0 : parseFloat(((row.profit / row.order_placed_price) * 100).toFixed(2))}%</span></TableCell>
                                        {/* <TableCell className={styles.tableCell}><Badge color={(row.transaction_status==='buy' || row.transaction_status==='sell') ? 'blue' : row.transaction_status==='complete' ? 'green':'red'} size='xs' >{row.transaction_status}</Badge></TableCell> */}
                                        <TableCell className={styles.tableCell}>
                                            <Tooltip
                                                label={ row.transaction_status === 'missed out'
                                                    ? row.initial_order_failure_reason || row.trade_status
                                                    : ''}
                                                position="top"
                                                withArrow
                                                disabled={row.transaction_status !== 'missed out'}
                                            >
                                            <Badge
                                            color={
                                                row.transaction_status === 'buy' || row.transaction_status === 'sell'
                                                ? 'blue'
                                                : row.transaction_status === 'complete'
                                                ? 'green'
                                                : 'red'
                                            }
                                            size="xs"
                                            >
                                            {row.transaction_status}
                                            </Badge>
                                        </Tooltip>
                                        </TableCell>
                                        <TableCell  className={styles.tableCell}>{formattedDate}</TableCell>
                                    </TableRow>
                                )})
                            }
                        </TableBody>
                </Table>
            </div>
            <div className={styles.transactionMobile}>
                <div className={styles.title}>
                    <Title className={styles.text}>Transaction</Title>
                    <SortBy onSortChange={handleSortChange}  />
                </div>
                <div className={styles.input}>
                        <TextInput onValueChange={(value:string)=>setSearch(value)} style={{padding:"0.8rem 2.5rem", fontSize:"1rem"}} icon={MdOutlineSearch} className={styles.inputField} placeholder='Search Bot, Instrument' />
                        <DateRangePicker
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter' ) {
                                    callForData();
                                }
                            }}
                            startDate={selectedDates.startDates}
                            endDate={selectedDates.endDates}
                            onChange={(startDate,endDate)=>handleDateChange(startDate,endDate)}
                            className="w-full"
                        />
                </div>
                <div className={styles.datas}>
                    <div className={styles.left}>
                            <Title className={styles.text}>Showing {currentPage} page of {totalPage} pages </Title>
                            <div className={styles.textButton}>
                                <FaAngleLeft onClick={()=>currentPage !== 1 && handlePrevPage()} size={20} /> <FaAngleRight onClick={()=>handleNextPage()} size={20} />
                            </div>
                    </div>
                    {
                        transactionData?.map((row, index:number)=>(
                            <Card className={styles.transactionCards}>
                                <div style={row.profit>0 ? {background:"#10B981"}:{background:"#F43F5E"}} className={styles.topCard}>
                                    <div className={styles.topleftCard}>
                                        <Title className={styles.bot}>{row.bot_name}</Title>
                                        <p>{row.instrument_token}</p>
                                    </div>
                                    <Title className={styles.toprightCard}>{Number.isNaN((row.profit/row.order_placed_price)*100) ? 0 : parseFloat(((row.profit / row.order_placed_price) * 100).toFixed(2))}%</Title>
                                </div>
                                <div className={styles.midCard}>
                                    <div className={styles.details}>
                                        <Title className={styles.detailsLeft}>B/S Price</Title>
                                        <Title className={styles.detailsRight}>₹ {row.order_placed_price==null ? 0 : row.order_placed_price} / {row.square_off_price==null ? 0 : row.square_off_price}</Title>
                                    </div>
                                    <div className={styles.details}>
                                        <Title className={styles.detailsLeft}>Units</Title>
                                        <Title className={styles.detailsRight}>{row.quantity}</Title>
                                    </div>
                                    <div className={styles.details}>
                                        <Title className={styles.detailsLeft}>Status</Title>
                                        <Title className={styles.detailsRight}><Badge color={(row.transaction_status==='buy' || row.transaction_status==='sell') ? 'blue' : row.transaction_status==='complete' ? 'green':'red'}>{row.transaction_status}</Badge></Title>
                                    </div>
                                    <div className={styles.details}>
                                        <Title className={styles.detailsLeft}>P/L</Title>
                                        <Title className={styles.detailsRight}>₹ {row.profit}</Title>
                                    </div>
                                    <div className={styles.details}>
                                        <Title className={styles.detailsLeft}>E. Time</Title>
                                        <Title className={styles.detailsRight}>{(row.transaction_status==='buy' || row.transaction_status==='sell') ? row.start_action_datetime : row.square_off_time}</Title>
                                    </div>
                                </div>
                            </Card>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Transaction