// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_setting__h74r3 .settings_passwordChange__5BXgX {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 25rem;
}
.settings_setting__h74r3 .settings_passwordChange__5BXgX .settings_inputs__4SnCj {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/settings/settings.module.scss"],"names":[],"mappings":"AAMI;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AAFR;AAGQ;EACI,aAAA;EACA,sBAAA;EACA,WAAA;AADZ","sourcesContent":["@import '../../global/styles/colour.scss';\n@import '../../global/styles/font.scss';\n@import '../../global/styles/layout.scss';\n@import '../../global/styles/media.scss';\n\n.setting{\n    .passwordChange{\n        display: flex;\n        flex-direction: column;\n        gap:1.5rem;\n        width: 25rem;\n        .inputs{\n            display: flex;\n            flex-direction: column;\n            gap: 0.8rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setting": `settings_setting__h74r3`,
	"passwordChange": `settings_passwordChange__5BXgX`,
	"inputs": `settings_inputs__4SnCj`
};
export default ___CSS_LOADER_EXPORT___;
