import { BadgeDelta, Button, Card, NumberInput, Select, SelectItem, Switch, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Title } from '@tremor/react'
import styles from './bots.module.scss';
import botTableData from './bot-table-data.json';
import { useEffect, useState } from 'react';
import { GoPencil } from "react-icons/go";
import { RiRobot3Line } from 'react-icons/ri';
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { FaEdit } from "react-icons/fa";
import axios from 'axios';
import { baseUrl } from '../../app/shared/baseUrl';
import { useAppSelector } from '../../app/hooks';
import { MdCancel } from "react-icons/md";
import { BsToggle2On } from "react-icons/bs";
import { BsToggle2Off } from "react-icons/bs";
import toast, { Toaster } from 'react-hot-toast';
import { FaSave } from "react-icons/fa";

interface BotData {
    id: string
    customer_id: string
    bot_name: string
    admin_id: string
    signal_name: string
    quantity: number
    is_enabled: boolean
    available_bots: string[]
    trading_symbol: any
    created_at: string
    updated_at: string
    average_return:number
}

function Bots() {
    const [botData, setBott]=useState<BotData[]>();
    const [editBotData, setEditBott]=useState<BotData[]>();
    const auth =useAppSelector((state) => state.auth)
    const [editView, setEditView]=useState<boolean[]>([]);
    const [saveButton, setSaveButton]=useState(false);
    const [offSwitch, setOffSWitch]=useState(false);

    async function setOffData(index:number, bot:string, quantity:number, enabled:boolean){
        await setData(index,bot,quantity,enabled);
        setOffSWitch(!offSwitch);
    }

    useEffect(()=>{
        addBotData();
    },[offSwitch])
    const Success = () => toast.success('Bot Data Updated!');
    async function getBotDetails(){
        try{
            const response = await axios.get(`${baseUrl}/api/customers/get-bot-details`,{
                headers: {
                "Authorization": `Bearer ${auth.authCode}`,
                "Content-type": "application/json",
                }
            })
            if(response.status==200){
                console.log(response.data);
                setBott(response.data);
            }
            }catch(error:any){
            //warning1();
        if (error.response.status === 401) {
            /* dispatch(setAuth(''));
            dispatch(setSessionData('unauthenticated'));
            router.push('/login'); */
        }
            }
    }

    useEffect(()=>{
        setEditBott(botData);
        setEditView([]);
        var temp:boolean[]=[]
        botData?.map(value=>{
            temp.push(false)
        })
        setEditView(temp);
    },[botData])

    async function addBotData() {
        try {
            const response = await axios.post(
                `${baseUrl}/api/customers/update-bot-details`,
                editBotData?.map(item => ({
                    bot_name: item.bot_name,
                    signal_name: item.signal_name,
                    quantity: item.quantity,
                    is_enabled: item.is_enabled
                })),
                {
                    headers: {
                        "Authorization": `Bearer ${auth.authCode}`,
                        "Content-type": "application/json",
                    }
                }
            );
    
            if (response && (response.status === 200 || response.status === 201)) {
                Success();
                setSaveButton(false);
                getBotDetails();
            } else {
                console.warn("Unexpected response structure:", response);
            }
    
        } catch (error: any) {
            console.error("Error updating bot data:", error);
    
            if (error.response) {
                console.error("Response data:", error.response.data);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Request setup error:", error.message);
            }
        }
    }
    

    async function handleClick(index:number){
        var temp:boolean[]=[]
        await editView.map((prev:boolean, indexJ:number)=>{
            temp.push(index==indexJ ? !prev : prev);
        })
        setEditView(temp);
    }

    async function setData(index:number, bot:string, quantity:number, enabled:boolean) {
        var temp:BotData[]=[]
        await editBotData?.map((values:BotData, indexj:number)=>{
            temp.push({
                ...values,
                bot_name:index==indexj ? bot : values.bot_name,
                quantity:index==indexj ? quantity : values.quantity,
                is_enabled:index==indexj ? enabled : values.is_enabled
            })
        })
        setEditBott(temp);
    }
    useEffect(()=>{
        if(editView.includes(true)){
            setSaveButton(true)
        }else{
            setSaveButton(false);
            setEditBott(botData);
        }
    },[editView])

    useEffect(()=>{
        getBotDetails();
    },[]);

    return (
        <div className={styles.bot}>
            <Toaster position='top-right'/>
            <div className={styles.title}><Title className={styles.text}>Bots</Title></div>
            <div className={styles.botPc}>
            <div className={styles.tableContainer}>
                <Table className={styles.table}>
                    <TableHead className={styles.tableHead}>
                        <TableRow className={styles.tableRow}>
                            <TableHeaderCell className={styles.tableHeadCell}>S.No</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Bot</TableHeaderCell>
                            <TableHeaderCell /* style={{textAlign:'start'}} */ className={styles.tableHeadCell}>Strategy</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Quantity</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Avg Return</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Trade</TableHeaderCell>
                            <TableHeaderCell className={styles.tableHeadCell}>Edit</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.tableBody}>
                        {
                            editBotData?.map((row, index:number)=>(
                                <TableRow className={styles.tableRow}>
                                    <TableCell className={styles.tableCell}>{index+1}</TableCell>
                                    <TableCell className={styles.tableCell}>{row.signal_name}</TableCell>
                                    <TableCell style={{width:"10rem"}} /* style={{textAlign:'start'}}  */className={styles.tableCell}>
                                    {editView[index] ? <Select onValueChange={(value)=>{setData(index,value, row.quantity, row.is_enabled)}} defaultValue={row.bot_name}>
                                        {
                                            row.available_bots.map(values=>(
                                                <SelectItem value={values}>{values.toUpperCase()}</SelectItem>
                                            ))
                                        }
                                    </Select> : row.bot_name==="" ? 'Not Selected!' : row.bot_name}</TableCell>
                                    <TableCell style={{width:"10rem"}} className={styles.tableCell}>{editView[index] ? <NumberInput onValueChange={(value:number)=>{setData(index,row.bot_name, value, row.is_enabled)}} value={row.quantity} placeholder='Quantity' enableStepper={false} /> : row.quantity}</TableCell>
                                    <TableCell className={styles.tableCell}><BadgeDelta deltaType={(row.average_return > 0) ? 'moderateIncrease':'moderateDecrease'} isIncreasePositive={true}>{row.average_return}%</BadgeDelta></TableCell>
                                    <TableCell style={{display:"flex", justifyContent:"center"}}  className={styles.tableCell}>{editView[index] ? <Switch  onChange={(value:boolean)=>{setData(index,row.bot_name, row.quantity, value)}} checked={row.is_enabled} onClick={()=>{
                                    }} /> : row.is_enabled ? <BsToggle2On onClick={()=>{ setOffData(index,row.bot_name, row.quantity, !row.is_enabled)}} color='blue' size={28} /> : <BsToggle2Off onClick={()=>{ setOffData(index,row.bot_name, row.quantity, !row.is_enabled)}} color='grey' size={25} /> }</TableCell>
                                    <TableCell onClick={()=>handleClick(index)} className={styles.tableCell}>{!editView[index] ? <GoPencil size={20} style={{display:"flex", color:"black", width:"100%", justifyContent:"center"}}/>: <MdCancel size={25} style={{display:"flex", color:"red", width:"100%", justifyContent:"center"}}/>}</TableCell>
                                </TableRow>
                            ))
                        }
                        {saveButton && <TableRow  ><TableCell/><TableCell/><TableCell/><TableCell/> <TableCell/><TableCell/><Button onClick={()=>{addBotData();}} style={{marginTop:"1rem"}} color='green' variant='secondary'>Save</Button></TableRow>}
                    </TableBody>
                </Table>
            </div>
            <div className={styles.notes}>
                <ul>
                    <li>This results is valid for today only, We do not directly or indirectly make any reference to the past or expected future return/performance of the Algorithm.</li>
                    <li> सभी प्रतिभूतियां एल्गो ट्रेडिंग सिस्टम बाजार जोखिमों के अधीन हैं और इस बात का कोई आश्वासन नहीं दिया जा सकता है कि उपयोगकर्ता के उद्देश्यों को आज के प्रदर्शन के आधार पर प्राप्त किया जाएगा। यह परिणाम केवल आज के लिए मान्य है।</li>
                </ul>
            </div>
        </div>
        <div className={styles.botMobile}>
            <div style={{width:"100%"}}>
            <p style={{fontSize:'1.3rem', fontWeight:'400', marginTop:"-1rem", textAlign:'start'}}>5 Active, 15 Inactive</p>
            </div>
            {
                editBotData?.map((sections, index)=>(
                    <Card className={styles.cards}>
                        <div style={!sections.is_enabled ? {background:"#334155", color:'white'} : {background:"#10B981", color:'white'}} className={styles.one}>
                            <div className={styles.left}>{sections.signal_name==='' ? 'Not Selected': sections.signal_name} {editView[index] ? <FaSave onClick={()=>{addBotData()}} />:<FaEdit onClick={()=>{handleClick(index)}} />}</div>
                           {/*  <BadgeDelta deltaType={(sections.average_return > 0) ? 'moderateIncrease':'moderateDecrease'} isIncreasePositive={true}>{sections.average_return}%</BadgeDelta> */}
                        </div>
                        <div className={styles.details}>
                            <Title className={styles.data}>{editView[index] ? <Select onValueChange={(value)=>{setData(index,value, sections.quantity, sections.is_enabled)}} defaultValue={sections.bot_name}>
                                        {
                                            sections.available_bots.map(values=>(
                                                <SelectItem value={values}>{values.toUpperCase()}</SelectItem>
                                            ))
                                        }
                                    </Select> : <><RiRobot3Line />{sections.bot_name==='' ? 'Not Selected': sections.bot_name}</>}</Title>
                            <Title className={styles.data}>{editView[index] ? <NumberInput onValueChange={(value:number)=>{setData(index,sections.bot_name, value, sections.is_enabled)}} value={sections.quantity} placeholder='Quantity' enableStepper={false} />  : <><div>(x)</div> {sections.quantity}</>}</Title>
                            <Title className={styles.data}><HiMiniArrowTrendingUp /> <span style={sections.average_return < 0 ? {color:'red'} : { color:'green'}}>{sections.average_return==null ? 0 : sections.average_return}%</span></Title>
                        </div>
                        {/* <div className={styles.two}>
                            <div className={styles.left}><AiTwotoneBank /> {sections.instrument}</div>
                            <></>
                        </div>
                        <div className={styles.two}>
                            <div className={styles.left}><IoCopyOutline /> {sections.quantity}</div>
                            <></>
                        </div> */}
                        <div className={styles.bottom}>
                            {/* <Button size='md' style={{padding:"5px 25px", fontSize:"25px"}} variant='secondary'>Edit</Button>
                            <Switch /> */}
                            <div onClick={()=>{editView[index] ? setData(index,sections.bot_name, sections.quantity, !sections.is_enabled) : setOffData(index,sections.bot_name, sections.quantity, !sections.is_enabled)}} style={!sections.is_enabled ? {background:"#6BC6A8", color:'white'} : {background:"#48576B", color:'white'}} className={styles.buttons}>{!sections.is_enabled ? 'Enable':'Disable'}</div>
                        </div>
                    </Card>
                ))

            }
        </div>
    </div>

    )
}

export default Bots