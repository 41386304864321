import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import styles from './navbar.module.scss';
import profileImage from '../../../assets/profile.png';
import { GiHamburgerMenu } from "react-icons/gi";
import { setHam } from '../../../app/Slice/hamSlice';
import { setAccount, setAuth, setAuthBoolean, setUserName } from '../../../app/Slice/authSlice';
import logo from '../../../assets/company.png';
import axios from 'axios';
import { baseUrl } from '../../../app/shared/baseUrl';
import { useEffect } from 'react';


export default function Navbar(){
    const accountName = useAppSelector((state) => state.auth)
    const hamStatus = useAppSelector((state)=>state.ham);
    const dispatch = useAppDispatch()

    function setLogout(){
        dispatch(setUserName('Unknown'));
        dispatch(setAccount("Login"));
        dispatch(setAuth(''));
        dispatch(setAuthBoolean(false));
    }

    useEffect(()=>{
        getAddedBrokers();
    },[])

    async function getAddedBrokers(){
        try {
            const response = await axios.get(`${baseUrl}/api/v1/broker-info/get-added-brokers`,{
                headers:{
                    'Authorization':`Bearer ${accountName.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
        } catch (error:any) {
            if(error.response.status===401){
                setLogout();
            }
        }
    }
    return(
        <nav>
            { accountName.auth ? <div onClick={()=>{dispatch(setHam(!hamStatus))}} className={styles.ham}><GiHamburgerMenu color='white' size={24} /></div> : <></>}
            {accountName.accountName==='Finvtech' ? <img style={{height:"2.5rem"}} src={logo}/> :  <p  className={styles.left}>{accountName.accountName}</p>}
            {
                accountName.auth ?
                <div  className={styles.right}>
                <div className={styles.account}>
                    <p className={styles.name}>{accountName.userName}</p>
                    <p className={styles.plan}>{accountName.sub}</p>
                </div>
                <div className={styles.image}>
                    <img src={profileImage} />
                </div>
            </div>
            :
            <div></div>
            }
            <div className={styles.rightMobile}/>
        </nav>
    )
}