import styles from './homepage.module.scss';

import { AreaChart, BadgeDelta, BarList, Card, Legend, Table, TableHead, TableHeaderCell, TableRow, Title } from '@tremor/react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import axios from 'axios';
import { setAccount, setUserName } from '../../app/Slice/authSlice';
import { baseUrl } from '../../app/shared/baseUrl';
import { useEffect } from 'react';
const datahero = [
  { name: 'bot 1', value: 456 },
  { name: 'bot 2', value: 351 },
  { name: 'bot 3', value: 51 },]

  const datahero2 = [
    { name: 'tradeId 1', value: 456 },
    { name: 'tradeId 2', value: 351 },
    { name: 'tradeId 3', value: 51 },]

const chartdata = [
  {
    date: 'Jan 23',
    'Bot 1': 167,
    'Bot 2': 145,
    'Open Water Swimming': 135,
    'Bot 3': 115,
    'Street Basketball': 150,
  },
  {
    date: 'Feb 23',
    'Bot 1': 125,
    'Bot 2': 110,
    'Open Water Swimming': 155,
    'Bot 3': 85,
    'Street Basketball': 180,
  },
  {
    date: 'Mar 23',
    'Bot 1': 156,
    'Bot 2': 149,
    'Open Water Swimming': 145,
    'Bot 3': 90,
    'Street Basketball': 130,
  },
  {
    date: 'Apr 23',
    'Bot 1': 165,
    'Bot 2': 112,
    'Open Water Swimming': 125,
    'Bot 3': 105,
    'Street Basketball': 170,
  },
  {
    date: 'May 23',
    'Bot 1': 153,
    'Bot 2': 138,
    'Open Water Swimming': 165,
    'Bot 3': 100,
    'Street Basketball': 110,
  },
  {
    date: 'Jun 23',
    'Bot 1': 124,
    'Bot 2': 145,
    'Open Water Swimming': 175,
    'Bot 3': 75,
    'Street Basketball': 140,
  },
  {
    date: 'Jan 23',
    'Bot 1': 167,
    'Bot 2': 145,
    'Open Water Swimming': 135,
    'Bot 3': 115,
    'Street Basketball': 150,
  },
  {
    date: 'Feb 23',
    'Bot 1': 125,
    'Bot 2': 110,
    'Open Water Swimming': 155,
    'Bot 3': 85,
    'Street Basketball': 180,
  },
  {
    date: 'Mar 23',
    'Bot 1': 156,
    'Bot 2': 149,
    'Open Water Swimming': 145,
    'Bot 3': 90,
    'Street Basketball': 130,
  },
  {
    date: 'Apr 23',
    'Bot 1': 165,
    'Bot 2': 112,
    'Open Water Swimming': 125,
    'Bot 3': 105,
    'Street Basketball': 170,
  },
  {
    date: 'May 23',
    'Bot 1': 153,
    'Bot 2': 138,
    'Open Water Swimming': 165,
    'Bot 3': 100,
    'Street Basketball': 110,
  },
  {
    date: 'Jun 23',
    'Bot 1': 124,
    'Bot 2': 145,
    'Open Water Swimming': 175,
    'Bot 3': 75,
    'Street Basketball': 140,
  },
]



export default function Homepage(){
  const dispatch=useAppDispatch();
  const auth= useAppSelector((state) => state.auth);

  useEffect(()=>{
    getCustomerDetails();
  },[])

  async function getCustomerDetails() {
    try {
        const response = await axios.get(`${baseUrl}/api/customer/get-details`,{
            headers: {
                "Authorization": `Bearer ${auth.authCode}`,
                "Content-type": "application/json",
                }
        })
        if(response.status===200 || response.status===201){
            dispatch(setUserName(response.data.name));
        }
    } catch (error) {
        dispatch(setAccount("UnKnown"));
    }
}
    return(
        <div className={styles.homepage}>
            <div className={styles.top}>
                <Card className={styles.cards}>
                    <div className="flex items-center justify-between">
                        <h4 style={{color:"black", fontSize:"1.125rem", fontWeight:"500"}} className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Profit/Loss</h4>
                        <BadgeDelta
                        deltaType="moderateIncrease"
                        isIncreasePositive={true}
                        size="xs"
                        >
                        0%
                        </BadgeDelta>
                    </div>
                    <div style={{display:"flex", alignItems:"end", gap:"10px", marginTop:"15px", justifyContent:"center"}}>
                    <p style={{fontSize:"2.5rem", fontWeight:"500"}} >0</p>
                    <p style={{fontSize:"20px", lineHeight:"2.5rem", fontWeight:"500"}}>₹</p>
                    </div>
                </Card>
                <Card className={styles.cards}>
                    <div className="flex items-center justify-between">
                        <h4 style={{color:"black", fontSize:"1.125rem", fontWeight:"500"}} className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Max Money Made</h4>
                    </div>
                    <div style={{display:"flex", alignItems:"end", gap:"10px", marginTop:"15px", justifyContent:"center"}}>
                        <p style={{fontSize:"2.5rem", fontWeight:"500"}} >0</p>
                        <p style={{fontSize:"20px", lineHeight:"2.5rem", fontWeight:"500"}}>₹</p>
                    </div>
                </Card>
                <Card className={styles.cards}>
                    <div className="flex items-center justify-between">
                        <h4 style={{color:"black", fontSize:"1.125rem", fontWeight:"500"}} className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Trades</h4>
                        <BadgeDelta
                        deltaType="moderateIncrease"
                        isIncreasePositive={true}
                        size="xs"
                        >
                        0%
                        </BadgeDelta>
                    </div>
                    <div style={{display:"flex", alignItems:"end", gap:"10px", marginTop:"15px", justifyContent:"center"}}>
                        <p style={{fontSize:"2.5rem", fontWeight:"500"}} >0</p>
                    </div>
                </Card>
            </div>
            <div className={styles.graph}>
                <Card className={styles.areaChart}>
                <AreaChart
                    data={chartdata}
                    index="date"
                    categories={[
                    'Bot 1',
                    'Bot 2',
                    'Bot 3',
                    ]}
                    className={styles.table}
                    colors={['green', 'blue-700', 'red']}
                    yAxisWidth={30}
                />
                </Card>
            </div>
            <div className={styles.bottom}>
                <Card className={styles.batchcards}>
                <Title style={{marginBottom:"12px", fontSize:"16px"}}>Top Boats</Title>
                <Table>
                    <TableHead>
                    <TableRow>
                    <TableHeaderCell><Title style={{fontSize:"16px"}}>Bots</Title></TableHeaderCell>
                    <TableHeaderCell className="text-right"><Title style={{fontSize:"16px"}}>Profits</Title></TableHeaderCell>
                    </TableRow>
                    </TableHead>
                </Table>
                <BarList data={datahero} className={styles.barList} />
                </Card>
                <Card className={styles.batchcards}>
                <Title style={{marginBottom:"12px", fontSize:"16px"}}>Top Trades</Title>
                <Table>
                    <TableHead>
                    <TableRow>
                    <TableHeaderCell><Title style={{fontSize:"16px"}}>Trades</Title></TableHeaderCell>
                    <TableHeaderCell className="text-right"><Title style={{fontSize:"16px"}}>Profits</Title></TableHeaderCell>
                    </TableRow>
                    </TableHead>
                </Table>
                <BarList data={datahero2} className={styles.barList} />
                </Card>
            </div>
        </div>
    )
}