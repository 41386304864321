import React, { useEffect, useState } from 'react';
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";

export type SortDirection = 'asc' | 'desc' | 'reload';

interface SortByProps {
        onSortChange: (direction: SortDirection) => void;
        initialDirection?: SortDirection;
    }

export function SortBy({ onSortChange }: SortByProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [direction, setDirection] = useState<SortDirection>();
    const [directionName, setDirectionName] = useState<String>('')

    const handleSort = (newDirection: SortDirection) => {
        setDirection(newDirection);
        onSortChange(newDirection);
        setIsOpen(false);
    };

    useEffect(()=>{
        direction==='asc' &&
        setDirectionName(': Date Ascending');
        direction==='desc' && setDirectionName(': Date Descending')
        direction==='reload' && window.location.reload();
    },[direction])
    return (
        <div className="relative">
        <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center gap-2 px-6 py-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition-colors duration-200 border-2 border-gray-200"
        >
            <span className="text-sm font-medium text-gray-800">Sort By {directionName}</span>
            <FaAngleDown className={`w-4 h-4 text-gray-600 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
        </button>
        {isOpen && (
            <div className="absolute top-full right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-10">
            <button
                onClick={() => handleSort('asc')}
                className={`w-full px-4 py-2 text-left hover:bg-gray-50 transition-colors duration-200 ${
                direction === 'asc' ? 'text-blue-600 bg-blue-50' : 'text-gray-700'
                }`}
            >
                Date ascending
            </button>
            <button
                onClick={() => handleSort('desc')}
                className={`w-full px-4 py-2 text-left hover:bg-gray-50 transition-colors duration-200 ${
                direction === 'desc' ? 'text-blue-600 bg-blue-50' : 'text-gray-700'
                }`}
            >
                Date descending
            </button>
            <button
                onClick={() => handleSort('reload')}
                className={`w-full px-4 py-2 text-left hover:bg-gray-50 transition-colors duration-200 ${
                direction === 'desc' ? 'text-blue-600 bg-blue-50' : 'text-gray-700'
                }`}
            >
                Reset
            </button>
            </div>
        )}
        </div>
    );
}