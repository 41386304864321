import { Button, Card, Divider, NumberInput, Select, SelectItem, TextInput, Title } from '@tremor/react';
import styles from './broker.module.scss';
import { FaChevronDown } from "react-icons/fa";
import broker_data from './broker.json';
import { useEffect, useState } from 'react';
import upstox from '../../assets/upstox.png';
import angleone from '../../assets/angleone.png';
import dhan from '../../assets/dhan.png';
import aliceblue from '../../assets/aliceblue.png';
import fyers from '../../assets/fyers.png';
import axios from 'axios';
import { baseUrl } from '../../app/shared/baseUrl';
import { useAppSelector } from '../../app/hooks';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { IoCopy } from "react-icons/io5";
import { setAccount, setAuth, setUserName } from '../../app/Slice/authSlice';

const getRedirectUri = (): string => {
    const currentOrigin = window.location.origin;
    if (currentOrigin === 'http://localhost:3008') {
      return `${currentOrigin}/upstox`;
    }
    return `${currentOrigin}/upstox`;
  };

  const getRedirectUriFy = (): string => {
    const currentOrigin = window.location.origin;
    if (currentOrigin === 'http://localhost:3008') {
      return `${currentOrigin}/fyers`;
    }
    return `${currentOrigin}/fyers`;
  };


const brokerImages: { [key: string]: string } = {
    upstox,
    angleone,
    dhan,
    aliceblue,
    fyers
};

interface Upstox{
    client_id:string,
    client_secret:string,
    grant_type:'authorization_code',
    redirect_uri:string
}
const initialUpstox:Upstox={
    client_id:"",
    client_secret:"",
    grant_type:"authorization_code",
    redirect_uri: getRedirectUri()
}

interface Fyers{
    client_id:string,
    secret_key:string,
    redirect_uri:string
}
const initialFyers:Fyers={
    client_id:"",
    secret_key:"",
    redirect_uri:getRedirectUriFy()
}


interface Angel{
    api_key:string,
    username:string,
    pwd:string,
    token:string
}
const initialAngel:Angel={
    api_key:"",
    pwd:"",
    token:"",
    username:""
}

interface Dhan{
    client_id:string,
    access_token:string,
    validity:string|undefined
}
const intialDhan:Dhan={
    client_id:"",
    access_token:"",
    validity:undefined
}

interface Alice{
    api_key:string,
    user_id:string,
}
const intialAlice:Alice={
    api_key:"",
    user_id:"",
}

interface AddedBroker{
    broker_name: string,
    is_primary: boolean,
    broker_logo: string
}

interface AvailableBroker{
    broker_name: string,
    broker_url: string
}


function Broker() {
    const navigate = useNavigate();

    const auth =useAppSelector((state) => state.auth)
    const [showDropDown, setShowDropDown]=useState<boolean[]>([]);
    const [showAddBrokerSelect, setShowAddBrokerSelect]=useState<boolean>(false);
    const [additionBroker, setAdditionBroker]=useState<String>("");
    const [view, setView]=useState(false);
    const [upstoxInputs, setUpstoxInputs]=useState<Upstox>(initialUpstox);
    const [dhanInputs, setDhanInputs]=useState<Dhan>(intialDhan);
    const [aliceInputs, setAliceInputs]=useState<Alice>(intialAlice);
    const [angelInputs, setAngelInputs]=useState<Angel>(initialAngel);
    const [fyersInputs, setFyersInputs]=useState<Fyers>(initialFyers);
    const [addedBrokers, setAddedBrokers]=useState<AddedBroker[]>();
    const [availableBrokers, setAvailableBrokers]=useState<AvailableBroker[]>();
    const [primaryBroker, setPrimaryBroker]=useState<string>()

    const brokerAdded = (broker:string) => toast.success(`${broker} Broker Added Successfully!`);
    const brokerset = (broker:string) => toast.success(`${broker} Broker set As primary!`);
    const brokerNotAdded = (broker:string) => toast.error(`Failed to add ${broker} Broker!`);
    const setingFailed = (broker:string) => toast.error(`Failed to set the ${broker} broker primary!`);
    const loadingFailed = () => toast.error('Failed to fetch the data re-try!');
    const authSet = (broker:string) => toast.success(`${broker} Broker Authenticated!`);
    const failedAuth = (broker:string) => toast.error(`Failed the Authenticate the ${broker} Broker!`)
    const noBroker = () => toast.error('No Broker Set Primary!');
    const deleteBrokerDone = (broker:string)=> toast.success(`${broker} deleted successfully!`);
    const deleteBrokerFailed = (broker:string)=> toast.error(`Failed to delete ${broker} try again!`);

    const noBrokerAvailable = () =>toast('No Broker Added kindly add the Broker!');



    function getPrimaryBroker(brokers:AddedBroker[]) {
        if(brokers){
            const primaryBroker = brokers.find(broker => broker.is_primary === true);
            if (primaryBroker) {
                setPrimaryBroker(primaryBroker.broker_name);
            }
        }
    }

    useEffect(()=>{
        getAddedBrokers();
        getAllBrokers();
    },[]);
    useEffect(()=>{
        addedBrokers?.map(value=> showDropDown.push(false));
        getPrimaryBroker(addedBrokers!);
    },[addedBrokers])
    async function setDropDown(index:number){
        var newvalue: boolean[]=[];
        await addedBrokers?.map((item, indexBroker)=>{
            newvalue.push(index== indexBroker ? true : false)
        })

        setShowDropDown(newvalue);
    }

    async function hideAllDropDown(){
        var newvalue: boolean[]=[];
        await addedBrokers?.map((item, indexBroker)=>{
            newvalue.push(false)
        })
        setShowDropDown(newvalue);
    }

    //auth api
    function selectAuthBroker(brokerName:string){
        switch (brokerName) {
            case "upstox":
                upstoxAuth();
                break;
            case "angleone":
                angelAuth();
                break;
            case "dhan":
                dhanAuth();
                break;
            case "aliceblue":
                aliceblueAuth();
                break;
                case "fyers":
                    fyersAuth();
                    break;
            default:
                noBroker();
                break;
        }
    }

    async function upstoxAuth(){
        console.log("Handling Upstox");
        try {
            const response = await axios.get(`${baseUrl}/api/v2/brokers/upstox/authcode-url-generation`,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if(response.status===200 || response.status===201){
                window.location.href = response.data.Link;
            }
        } catch (error) {
            failedAuth('Upstox')
        }
    }

    async function angelAuth(){
        console.log("Handling Angel one");
        try {
            const response = await axios.post(`${baseUrl}/api/v2/brokers/angleone/authenticate-broker`,{},{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if(response.status==200 || response.status==201){
                authSet('Angel One');
            }
        } catch (error) {
            failedAuth('Angel One');
        }
    }

    async function dhanAuth(){
        console.log("Handling Dhan");
        try {
            const response = await axios.patch(`${baseUrl}/api/v2/broker/dhan/authenticate-for-today?authenticate=true`,{},{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if(response.status==200 || response.status==201){
                authSet('Dhan')
            }
        } catch (error) {
            failedAuth('Dhan')
        }
    }

    async function aliceblueAuth(){
        console.log("Handling Alice Blue");
        try {
            const response = await axios.patch(`${baseUrl}/api/v2/broker/alice-blue/authenticate-for-today`,{},{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if(response.status==200 || response.status==201){
                authSet('Alice Blue')
            }
        } catch (error) {
            failedAuth('Alice Blue')
        }
    }

    async function fyersAuth(){
        console.log("Handling Fyers");
        try {
            const response = await axios.post(`${baseUrl}/api/v2/broker/fyers/generate-token-url`,{},{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if(response.status===200 || response.status===201){
                window.location.href = response.data.token_url;
            }
        } catch (error) {
            failedAuth('Fyers')
        }
    }

    // take action apu

    async function setPrimary(brokerData:string) {
        hideAllDropDown();
        console.log(brokerData)
        try {
            const response = await axios.patch(`${baseUrl}/api/v1/broker-info/set-primary-broker?primary_broker=${brokerData}`,{},{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                brokerset(brokerData);
                getAddedBrokers();
            }
        } catch (error) {
            setingFailed(brokerData);
        }
    }

    // delete api

    async function deleteBroker(params:string) {
        console.log(params)
        try {
            const response = await axios.delete(`${baseUrl}/api/v1/broker-info/delete-broker?broker=${params}`,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                deleteBrokerDone(params)
                getAddedBrokers();
            }
        } catch (error:any) {
            deleteBrokerFailed(params)
        }
    }

    // get api here

    async function getAddedBrokers(){
        try {
            const response = await axios.get(`${baseUrl}/api/v1/broker-info/get-added-brokers`,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if (response.status==200 || response.status==201){
                setAddedBrokers(response.data.data);
            }
        } catch (error:any) {

            if(error.response.status===404){
                noBrokerAvailable()
            }
            else{
                loadingFailed();
            }
            //setAddedBrokers(broker_data);
        }
    }

    async function getAllBrokers(){
        try {
            const response = await axios.get(`${baseUrl}/api/v1/broker-info/not-added-brokers`,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            });
            if (response.status==200 || response.status==201){
                setAvailableBrokers(response.data.available_brokers);
            }
        } catch (error:any) {
            loadingFailed();
        }
    }

    // adding api here
    async function addUpstoxBroker(data:Upstox){
        console.log(data);
        try{
            const response=await axios.post(`${baseUrl}/api/v2/brokers/upstox/add-broker-details`,data,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                brokerAdded("Upstox");
                setAdditionBroker('');
                setUpstoxInputs(initialUpstox);
                setShowAddBrokerSelect(false);
                setView(false);
                getAddedBrokers();
            }
        }catch(error:any){
            brokerNotAdded('Upstox');
            console.error(error);
        }
    }

    async function addAngelBroker(data:Angel){
        console.log(data);
        try{
            const response=await axios.post(`${baseUrl}/api/v2/brokers/angleone/add-broker-details`,data,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                brokerAdded("Angel One");
                setAdditionBroker('');
                setUpstoxInputs(initialUpstox);
                setShowAddBrokerSelect(false);
                setView(false);
                getAddedBrokers();
            }
        }catch(error:any){
            brokerNotAdded('Angel One');
            console.error(error);
        }
    }

    async function addDhanBroker(data:Dhan){
        console.log(data);
        try{
            const response=await axios.post(`${baseUrl}/api/v2/broker/dhan/add-broker-details`,data,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                brokerAdded("Dhan");
                setAdditionBroker('');
                setUpstoxInputs(initialUpstox);
                setShowAddBrokerSelect(false);
                setView(false);
                getAddedBrokers();
            }
        }catch(error:any){
            brokerNotAdded('Dhan');
            console.error(error);
        }
    }

    async function addAliceBroker(data:Alice){
        console.log(data);
        try{
            const response=await axios.post(`${baseUrl}/api/v2/broker/alice-blue/add-broker-details`,data,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                brokerAdded("Alice Blue");
                setAdditionBroker('');
                setUpstoxInputs(initialUpstox);
                setShowAddBrokerSelect(false);
                setView(false);
                getAddedBrokers();
            }
        }catch(error:any){
            brokerNotAdded('Alice Blue');
            console.error(error);
        }
    }

    async function addFyersBroker(data:Fyers){
        console.log(data);
        try{
            const response=await axios.post(`${baseUrl}/api/v2/broker/fyers/add-broker-details`,data,{
                headers:{
                    'Authorization':`Bearer ${auth.authCode}`,
                    'Content-Type':'application/json'
                    }
            })
            if(response.status==200 || response.status==201){
                brokerAdded("Fyers");
                setAdditionBroker('');
                setUpstoxInputs(initialUpstox);
                setShowAddBrokerSelect(false);
                setView(false);
                getAddedBrokers();
            }
        }catch(error:any){
            brokerNotAdded('Fyers');
            console.error(error);
        }
    }

    return (
        <div className={styles.broker}>
            <Toaster position='top-right'/>
            <div className={styles.topBroker}>
                <div className={styles.top}>
                    <Title className={styles.text}>Brokers</Title>
                    <div style={{display:"flex", width:"30rem",gap:"1rem"}}>
                    <Select value='3' onValueChange={(value)=>{
                                        navigator.clipboard
                                        .writeText(value)
                                        .then(() => console.log('Copied to clipboard!'))
                                        .catch((err) => console.error('Failed to copy text: ', err));
                                    }} placeholder='Select Redirect URL'>
                        <SelectItem  onClick={() => {
                                        navigator.clipboard
                                        .writeText(upstoxInputs.redirect_uri)
                                        .then(() => console.log('Copied to clipboard!'))
                                        .catch((err) => console.error('Failed to copy text: ', err));
                                    }}  value={upstoxInputs.redirect_uri}><div style={{display:"flex", width:"100%", gap:"1.5rem",alignItems:"center", justifyContent:"space-between"}}> <IoCopy size={18} /> Upstox :  {upstoxInputs.redirect_uri} </div></SelectItem>
                        <SelectItem onClick={() => {
                                        navigator.clipboard
                                        .writeText(fyersInputs.redirect_uri)
                                        .then(() => console.log('Copied to clipboard!'))
                                        .catch((err) => console.error('Failed to copy text: ', err));
                                    }} value={fyersInputs.redirect_uri}><div style={{display:"flex", width:"100%", gap:"1.5rem",alignItems:"center", justifyContent:"space-between"}}> <IoCopy size={18} /> Fyers : {fyersInputs.redirect_uri}</div></SelectItem>
                    </Select>
                        <Button onClick={()=>{setShowAddBrokerSelect(!showAddBrokerSelect)}} variant='secondary'>+ Add Broker</Button>
                    </div>
                </div>
                <div className={styles.top2}>
                    <Title className={styles.text2}>Primary Broker</Title>
                        <div style={{padding: '.5rem', backgroundColor: 'white', width: '15rem', borderRadius: '.5rem'}}>
                            {brokerImages[primaryBroker!] ? <img style={{margin: '0 auto'}} alt={primaryBroker!} src={brokerImages[primaryBroker!]} /> :<Title>No Primary Broker</Title>}
                        </div>
                </div>
                <div className={styles.addBroker}>
                    <Title className={styles.text2}>Added Brokers</Title>
                    <div className={styles.brokers}>
                    {
                        addedBrokers?.map((brokers, index)=>(
                            <Card className={styles.cards}>
                                <div style={{width:"1px"}}></div>
                                <img alt={brokers?.broker_name} src={brokerImages[brokers?.broker_name]} />
                                <FaChevronDown onClick={()=>{
                                    showDropDown[index] ? hideAllDropDown() : setDropDown(index);
                                }} />
                                {
                                    showDropDown[index] &&
                                    <Card className={styles.select} >
                                        <option onClick={()=>{setPrimary(brokers?.broker_name)}} className={styles.options} value="one">Make this Primary</option>
                                        <Divider style={{padding:'0', margin:'0'}}/>
                                        <option onClick={()=>{setView(true); setAdditionBroker(brokers?.broker_name); hideAllDropDown() }} className={styles.options} value="two">Edit</option>
                                        <Divider style={{padding:'0', margin:'0'}}/>
                                        <option onClick={()=>{deleteBroker(brokers?.broker_name); hideAllDropDown()}} className={styles.options} value="three">Delete</option>
                                    </Card>
                                }
                            </Card>
                        ))
                    }
                    </div>
                </div>
            </div>
            <div className={styles.mobileTopBroker}>
                <div className={styles.top}>
                    <Title  className={styles.text}>Brokers</Title>
                    <div className={styles.functionButtons}>
                        <Button  onClick={()=>{setShowAddBrokerSelect(!showAddBrokerSelect)}} variant='secondary'><span className={styles.buttonStyle}>+ Add Broker</span></Button>
                        <Button  onClick={()=>{selectAuthBroker(primaryBroker ? primaryBroker : "")}} variant='secondary'><span className={styles.buttonStyle}>Authorize for today</span></Button>
                    </div>
                </div>
                <div className={styles.top2}>
                    <Title className={styles.text2}>Primary Broker</Title>
                    <div style={{padding: '1rem', backgroundColor: 'white' }}>
                        <img alt={primaryBroker!} src={brokerImages[primaryBroker!]} />
                    </div>
                </div>
                <div className={styles.addBroker}>
                    <Title className={styles.text2}>Added Brokers</Title>
                    <div className={styles.brokers}>
                        {
                            addedBrokers?.map((brokers, index)=>(
                                <div className={styles.cards}>
                                    {/* <div style={{width:"1px"}}></div> */}
                                    <img alt={brokers?.broker_name} src={brokerImages[brokers?.broker_name]} />
                                    {/* <FaChevronDown onClick={()=>{
                                        showDropDown[index] ? hideAllDropDown() : setDropDown(index);
                                    }} /> */}
                                    {
                                        /* showDropDown[index] && */
                                        <div className={styles.select} >
                                            <option onClick={()=>{setPrimary(brokers?.broker_name)}} className={styles.options} value="one">Make this Primary</option>
                                            <option onClick={()=>{setView(true); setAdditionBroker(brokers?.broker_name); hideAllDropDown() }} className={styles.options} value="two">Edit</option>
                                            <option onClick={()=>{deleteBroker(brokers?.broker_name); hideAllDropDown()}} className={styles.options} value="three">Delete</option>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            { //Drop down
                showAddBrokerSelect &&
                <Card className={styles.selectBroker}>
                    <Title>Select Broker</Title>
                    <Select className={styles.selectDrop} onValueChange={(value)=>setAdditionBroker(value)} placeholder='Select Brokers' >
                        {
                            /* [{name:"Upstox", broker_name:"upstox"}, {name:"Angel One", broker_name:"angleone"}, {name:"Dhan", broker_name:"dhan"}, {name:"Alice Blue", broker_name:"aliceblue"}, {name:"Fyers", broker_name:"fyers"}] */
                            availableBrokers?.map(brokers=>(
                                <SelectItem style={{ display:"flex", gap:"1rem", justifyContent:"center", alignItems:"center"}} value={brokers.broker_name}><img style={{width:"7rem"}} alt={brokers.broker_name} src={brokerImages[brokers.broker_name]} /></SelectItem>
                            ))
                        }
                    </Select>
                    <div className={styles.addButtons}>
                        <Button disabled={additionBroker===''} onClick={()=>{setView(true); setShowAddBrokerSelect(false)}} variant='secondary'>Add Broker</Button>
                        <Button onClick={()=>{setAdditionBroker(''); setShowAddBrokerSelect(false)}} color='red'>Cancel</Button>
                    </div>
                </Card>
            }
            { //Dhan
                (view && additionBroker==='dhan') &&
                <Card className={styles.dhan}>
                    <Title>Add Dhan Broker Details</Title>
                    <TextInput className={styles.inputBoxMobile} value={dhanInputs.client_id} onValueChange={(value)=>{
                        setDhanInputs(prev=>(
                            {
                                ...prev,
                                client_id:value
                            }
                        ))
                    }} placeholder='Client Id' />
                    <TextInput onValueChange={(value)=>{
                        setDhanInputs(prev=>(
                            {
                                ...prev,
                                access_token:value
                            }
                        ))
                    }} value={dhanInputs.access_token} placeholder='Access Token' />
                    <NumberInput enableStepper={false} onValueChange={(value)=>{
                        setDhanInputs(prev=>(
                            {
                                ...prev,
                                validity: value.toString()
                            }
                        ))
                    }} value={dhanInputs.validity} placeholder='Validity' />
                    <div className={styles.addButtons}>
                        <Button disabled={additionBroker===''} onClick={()=>{addDhanBroker(dhanInputs)}} variant='secondary'>Add Dhan Broker</Button>
                        <Button onClick={()=>{setAdditionBroker(''); setShowAddBrokerSelect(false); setView(false)}} color='red'>Cancel</Button>
                    </div>
                </Card>
            }
            { //Upstox
                (view && additionBroker==='upstox') &&
                <Card className={styles.dhan}>
                    <Title>Add Upstox Broker Details</Title>
                    <TextInput onValueChange={(value)=>{setUpstoxInputs((prev)=>(
                        {
                            ...prev,
                            client_id:value
                        }
                    ))}} value={upstoxInputs?.client_id} placeholder='API Key' />
                    <TextInput onValueChange={(value)=>{setUpstoxInputs((prev)=>(
                        {
                            ...prev,
                            client_secret:value
                        }
                    ))}} value={upstoxInputs?.client_secret} placeholder='API Secret' />
                    <div className={styles.addButtons}>
                        <Button disabled={additionBroker===''} onClick={()=>{addUpstoxBroker(upstoxInputs);}} variant='secondary'>Add Upstox Broker</Button>
                        <Button onClick={()=>{setAdditionBroker(''); setShowAddBrokerSelect(false); setView(false)}} color='red'>Cancel</Button>
                    </div>
                </Card>
            }
            { //Alice Blue
                (view && additionBroker==='aliceblue') &&
                <Card className={styles.dhan}>
                    <Title>Add Alice Blue Broker Details</Title>
                    <TextInput onValueChange={(value)=>{setAliceInputs(prev=>(
                        {
                            ...prev,
                            api_key:value
                        }
                    ))}} value={aliceInputs.api_key} placeholder='Api Key' />
                    <TextInput onValueChange={(value)=>{setAliceInputs(prev=>(
                        {
                            ...prev,
                            user_id:value
                        }
                    ))}} value={aliceInputs.user_id} placeholder='User ID' />
                    <div className={styles.addButtons}>
                        <Button disabled={additionBroker===''} onClick={()=>{addAliceBroker(aliceInputs)}} variant='secondary'>Add Alice Blue Broker</Button>
                        <Button onClick={()=>{setAdditionBroker(''); setShowAddBrokerSelect(false); setView(false)}} color='red'>Cancel</Button>
                    </div>
                </Card>
            }
            { //Fyers
                (view && additionBroker==='fyers') &&
                <Card className={styles.dhan}>
                    <Title>Add Fyers Broker Details</Title>
                    <TextInput onValueChange={(value)=>{setFyersInputs(prev=>(
                        {
                            ...prev,
                            client_id:value
                        }
                    ))}} value={fyersInputs.client_id} placeholder='App ID' />
                    <TextInput onValueChange={(value)=>{setFyersInputs(prev=>(
                        {
                            ...prev,
                            secret_key:value
                        }
                    ))}} value={fyersInputs.secret_key} placeholder='Secret ID' />
                    <div className={styles.addButtons}>
                        <Button disabled={additionBroker===''} onClick={()=>{addFyersBroker(fyersInputs)}} variant='secondary'>Add Fyers Broker</Button>
                        <Button onClick={()=>{setAdditionBroker(''); setShowAddBrokerSelect(false); setView(false)}} color='red'>Cancel</Button>
                    </div>
                </Card>
            }
            { //Angel One
                (view && additionBroker==='angleone') &&
                <Card className={styles.dhan}>
                    <Title>Add Angel One Broker Details</Title>
                    <TextInput onValueChange={(value)=>{
                        setAngelInputs(prev=>(
                            {
                                ...prev,
                                api_key:value
                            }
                        ))
                    }} value={angelInputs.api_key} placeholder='API key' />
                    <TextInput onValueChange={(value)=>{
                        setAngelInputs(prev=>(
                            {
                                ...prev,
                                username:value
                            }
                        ))
                    }} value={angelInputs.username} placeholder='User ID' />
                    <TextInput onValueChange={(value)=>{
                        setAngelInputs(prev=>(
                            {
                                ...prev,
                                pwd:value
                            }
                        ))
                    }} value={angelInputs.pwd} placeholder='M-Pin' />
                    <TextInput onValueChange={(value)=>{
                        setAngelInputs(prev=>(
                            {
                                ...prev,
                                token:value
                            }
                        ))
                    }} value={angelInputs.token} placeholder='Token' />
                    <div className={styles.addButtons}>
                        <Button disabled={additionBroker===''} onClick={()=>{addAngelBroker(angelInputs)}} variant='secondary'>Add Angel One Broker</Button>
                        <Button onClick={()=>{setAdditionBroker(''); setShowAddBrokerSelect(false); setView(false)}} color='red'>Cancel</Button>
                    </div>
                </Card>
            }
            <Button className={styles.authButton} onClick={()=>{selectAuthBroker(primaryBroker ? primaryBroker : "")}} variant='secondary'>Authorise for today</Button>
            <div className={styles.mobileSpace} />
        </div>

    )
}

export default Broker

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
