import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: boolean = false;

export const hamSlice = createSlice({
    name: 'ham',
    initialState,
    reducers:{
        setHam: (state, action: PayloadAction<boolean>) => state= action.payload,
        hide:(state)=> state=false

    }
});

export const { setHam, hide } = hamSlice.actions;
export const selectRoute = (state: RootState) => state.ham;

export default hamSlice.reducer;