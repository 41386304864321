import React from 'react'
import Navbar from '../Navbar/navbar'
import Sidebar from '../Sidebar/sidebar'
import styles from './control.module.scss';

export default function Control() {
    return (
        <div className={styles.control}>
            <Navbar />
            <Sidebar />
        </div>
    )
}
