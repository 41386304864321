import { Button, Card, TextInput, Title } from '@tremor/react';
import styles from './settings.module.scss';
import { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../app/shared/baseUrl';
import { useAppSelector } from '../../app/hooks';
import toast, { Toaster } from 'react-hot-toast';

function Settings() {
    const [newPassword, setNewPassword]=useState<string>('');
    const [confirmPassword, setConfirmPassword]=useState<string>('');
    const [noMatch, setNoMatch]=useState(false);
    const auth= useAppSelector((state) => state.auth);
    const Success = () => toast.success('Password Changed successfully!');
    const Failed = () => toast.error('Password Changed Failed try again!');

    async function changePassword(one:string, two:string) {
        if(one===two){
            try {
                const response = await axios.patch(`${baseUrl}/api/customer/change-password`,{
                    new_password: one
                },{
                    headers: {
                        "Authorization": `Bearer ${auth.authCode}`,
                        "Content-type": "application/json",
                        }
                })
                if(response.status===200 || response.status===201){
                    Success();
                    setNewPassword('')
                    setConfirmPassword('')
                }
            } catch (error) {
                Failed();
            }
        }
        else{
            setNoMatch(true);
        }
    }
    return (
        <div className={styles.setting}>
            <Toaster position='top-right' />
            <div className={styles.passwordChange}>
                <Title>Change Password</Title>
                <div className={styles.inputs}>
                    <TextInput value={newPassword} onValueChange={(val)=>{setNewPassword(val);setNoMatch(false);}} type='password' placeholder='New Password' />
                    <TextInput value={confirmPassword} onValueChange={(val)=>{setConfirmPassword(val);setNoMatch(false);}} type='password' placeholder='Confirm Password'  />
                        {noMatch && <p style={{color:'red'}}>password didn't match</p>}
                    <Button disabled={newPassword=='' || confirmPassword==''} onClick={()=>{changePassword(newPassword, confirmPassword)}}>Update</Button>
                </div>
                
            </div>
        </div>
    )
}

export default Settings