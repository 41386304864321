import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: string = '';

export const routeSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        setRoute: (state, action: PayloadAction<string>) => state= action.payload,
    },
});

export const { setRoute } = routeSlice.actions;
export const selectRoute = (state: RootState) => state.route;

export default routeSlice.reducer;