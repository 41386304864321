import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './Slice/authSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { routeSlice } from './Slice/routeSlice'
import hamSlice from './Slice/hamSlice'

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    route: routeSlice.reducer,
    ham: hamSlice
})


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)

export default store;
